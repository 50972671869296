<template>
  <Layout>
    <PageHeader
      :title="title"
      moda_title="Jugadores en linea"
      ref="pageForm"
      :items="items"
    >
    </PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-3">
              <div
                class="col-sm-12 col-12 d-flex justify-content-start"
                @change="getGames"
              >
                <label
                  class="d-inline-flex align-items-center"
                  style="width: max-content"
                >
                  {{ $t('filter.gameType') }}:
                </label>
                <select
                  v-model="filterData.gameType"
                  name="client"
                  class="form-select form-select-sm my-2 ms-2 border-0 shadow-lg w-50"
                  placeholder="Seleccione.."
                >
                  <option disabled selected>
                    {{ $t('helpers.select') }}...
                  </option>
                  <option
                    v-for="(item, index) in gameList"
                    :key="index"
                    :value="item.value"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="col-sm-12 col-md-4" v-if="isAdmin">
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t('filter.client') }}:
                  <select
                    v-model="filterData.clientId"
                    @change="getOperatorClient(true)"
                    name="client"
                    class="form-select form-select-sm m-2 border-0 shadow-lg"
                    placeholder="Seleccione.."
                  >
                    <option disabled selected>
                      {{ $t('helpers.select') }}...
                    </option>
                    <option
                      v-for="(item, index) in clients"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
              <div
                class="col-sm-12 col-md-4"
                v-if="!isOperator && filterData.clientId"
              >
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t('filter.operator') }}:
                  <select
                    v-model="filterData.operator"
                    name="client"
                    class="form-select form-select-sm m-2 border-0 shadow-lg"
                    placeholder="Seleccione.."
                  >
                    <option disabled selected>
                      {{ $t('helpers.select') }}...
                    </option>
                    <option
                      v-for="(item, index) in operators"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="col-sm-12">
                <vs-button
                  type="button"
                  success
                  @click="cancelFilter"
                  :disabled="!Object.entries(filterData).length"
                >
                  {{ $t('filter.cancel') }}
                </vs-button>
              </div>
            </div>
            <div
              v-if="
                Object.keys(filterData).includes('gameType') &&
                !gamesConfig.length
              "
            >
              <p class="text-center text-secondary fw-bold">
                Esperando por conexiones...
              </p>
            </div>
            <div class="row" v-if="gamesConfig.length">
              <div
                v-for="item in gamesConfig"
                :key="item.id"
                class="card col-4 card-chart"
              >
                <img :src="item.logo" alt="" class="card-img-top" />
                <div class="card-body">
                  <p><strong>Game:</strong>{{ item.name }}</p>
                  <p><strong>Players online:</strong>{{ item.online }}</p>
                </div>
              </div>
            </div>
            <div class="col-4 mx-auto" v-if="gamesConfig.length">
              <apexchart
                width="100%"
                type="donut"
                :options="options"
                :series="series"
              >
              </apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { io } from 'socket.io-client';
import Layout from '@/views/layouts/main';
import PageHeader from '@/components/page-header-simple';
import { breadCrumbData } from '../../../../helpers/breadcrumbs/breadCrumbs';

export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: 'JUGADORES EN LINEA',
      items: breadCrumbData.transactions,
      filterData: {},
      gamesConfig: [],
      gamesVisits: {},
      games: [],
      options: {
        labels: [],
      },
      series: [],
      SOCKET_URL: '',
      socket: null,
    };
  },
  computed: {
    ...mapGetters({
      clients: 'clients/getClients',
      operators: 'operators/getOperators',
      // GAMES
      mapGames: 'games/getGames',
      getClientRoulettes: 'clients/getClientRoulettes',
      // USER
      userData: 'user/getUserData',
      isAdmin: 'user/isAdmin',
      isOperator: 'user/isOperator',
      gameList: 'reports/getGamesTypes',
      userIsClient: 'user/isClient',
    }),
  },
  methods: {
    async getGames() {
      this.resetVars();

      const url = await this.selectApiUrl({ type: this.filterData.gameType });

      try {
        if (this.filterData.clientId) {
          const games = await this.fetchClientGames({
            ...this.filterData,
            client: this.filterData.clientId,
          });
          this.games = games;
        } else {
          await this.fetchGames({ gameType: this.filterData.gameType });
          this.games = this.mapGames;
        }

        this.createSocket(url);
      } catch (error) {
        console.log('ERROR GET GAMES', error);
        this.games = [];
      }
    },
    createGamesVisits() {
      this.gamesVisits = {};
      for (let game of this.games) {
        let gameId = '';
        const { _id: id, name, logo, uuid } = game;

        gameId = uuid ? uuid : id;

        this.gamesVisits[gameId] = {
          gameId,
          logo,
          name,
          online: 0,
        };
      }
    },
    resetVars() {
      this.gamesConfig = [];
      this.series = [];
    },
    createSocket(url) {
      this.socket = io(url, {
        transports: ['websocket'],
        query: {
          userId: 'SRPINT',
          rouletteId: 'GAMES',
          gameUuid: 'GAMES',
          operator: 'X',
          crupierdo: true,
          ...this.filterData,
        },
      });

      this.listenEvents();
    },
    listenEvents() {
      this.socket.on('users-online', (data) => {
        this.resetVars();
        this.createGamesVisits();

        let count = {};
        let gamesData = {};

        const { usersOnline } = data;

        if (!Object.entries(this.filterData).length) return;

        for (let user of usersOnline) {
          const { room, operator } = user;
          const game = this.games.find(
            ({ _id, uuid }) => String(uuid ? uuid : _id) === room
          );

          if (this.filterData.operator && this.filterData.operator !== operator)
            continue;

          if (!game) continue;
          const { name, _id: id, logo, uuid } = game;

          if (count[room]) {
            count[room] = count[room] + 1;
            Object.assign(gamesData, {
              [room]: {
                name,
                online: count[room],
                id: uuid ? uuid : id,
                logo,
              },
            });
            continue;
          }

          count[room] = 1;
          Object.assign(gamesData, {
            [room]: {
              name,
              online: count[room],
              id: uuid ? uuid : id,
              logo,
            },
          });
        }

        this.gamesVisits = {
          ...this.gamesVisits,
          ...gamesData,
        };

        for (let key in this.gamesVisits) {
          const game = this.gamesVisits[key];
          this.gamesConfig.push(game);
        }

        this.options = { labels: this.gamesConfig.map((gm) => gm.name) };
        const totalOnline = this.gamesConfig.reduce(
          (counter, arr) => (counter += arr.online),
          0
        );

        if (!totalOnline) {
          this.series = Array(this.gamesConfig.length).fill(0);
          return;
        }

        for (let game of this.gamesConfig) {
          const { online } = game;
          const part = (online * 100) / totalOnline;
          this.series.push(part);
        }
      });
    },
    async getOperatorClient(filter) {
      const clientId = filter ? this.filterData.clientId : this.userData.client;
      try {
        const options = { params: { client: clientId } };
        await this.fetchOperators({ options });
      } catch (err) {
        console.log('Error al obtener operadores del cliente', err);
      }
    },
    getUserRole() {
      if (this.userIsClient) {
        this.filterData.clientId = this.userData.client;
        // this.getOperatorClient();
        // this.getRoulettesClient();
      } else if (this.userData.rol === 'OPERATOR') {
        this.filterData.operator = this.userData.operator;
        // this.getRoulettesOperator();
      } else {
        this.fetchClients();
      }
    },
    cancelFilter() {
      this.filterData = {};
      if (this.socket) this.socket.disconnect();
      this.resetVars();
    },
    ...mapActions({
      fetchGames: 'games/fetchGames',
      selectApiUrl: 'games/selectApiUrl',
      fetchClients: 'clients/fetchClients',
      fetchOperators: 'operators/fetchOperators',
      fetchClientRoulettes: 'clients/fetchClientRoulettes',
      fetchClientGames: 'clients/fetchGames',
    }),
  },
  mounted() {
    this.getUserRole();
  },
  beforeDestroy() {
    this.socket.disconnect();
  },
  watch: {
    ['filterData.gameType']() {
      if (this.socket) this.socket.disconnect();
    },
  },
};
</script>

<style scoped>
.card-chart {
  max-height: 200px;
  display: grid;
  grid-template-columns: 20% 80%;
  max-width: 300px;
}

.card-chart > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
</style>
